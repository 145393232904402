<template>
    <v-container fluid>
        <!-- Key points -->
        <v-row no-gutters class="mb-4">
            <v-col xs="12" md="3" class="pr-2">
                <v-card>
                    <v-card-text class="text-center display-1 font-weight-light" v-if="order != null">
                        {{ order.status }}
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="grey lighten-3">
                        <div class="text-center subheading" style="width:100%">
                            Status
                        </div>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col xs="12" md="3" class="pl-2">
                <v-card>
                    <v-card-text class="text-center display-1 font-weight-light" v-if="order != null">
                        {{ order.foreignId }}
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="grey lighten-3">
                        <div class="text-center subheading" style="width:100%">
                            Wintux ID
                        </div>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col xs="12" md="3" class="pl-2">
                <v-card>
                    <v-card-text class="text-center" v-if="order != null">
                        <span class="display-1 font-weight-light">{{ formatDate(order.wantDate, 'YYYY-MM-DD') }}</span>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="grey lighten-3">
                        <div class="text-center subheading" style="width:100%">
                            Pickup Date
                        </div>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col xs="12" md="3" class="pl-3">
                <v-card>
                    <v-card-text class="text-center display-1 font-weight-light" v-if="order != null">
                        {{ formatMoney( balance ) }}
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="grey lighten-3">
                        <div class="text-center subheading" style="width:100%">
                            Balance
                        </div>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col cols="9">
                <v-card flat class="mr-3" :loading="loading">
                    <v-card-text v-if="order != null">
                        <v-row>
                            <v-col cols="4" align-self="start">
                                <customer-info :customer="order.customer" :edit="false" :avatar="false" show-measurements>
                                </customer-info>
                            </v-col>

                            <v-col cols="4">
                                <div class="display-1 font-weight-light grey--text text--darken-4">Event</div>
                                <div v-if="order.eventId == 0">This order is not associated with any event.</div>
                                <v-row no-gutters v-else>
                                    <v-col cols="3"><div>Code:</div></v-col>
                                    <v-col cols="3"><div>{{ order.event.foreignKey }}</div></v-col>
                                    <v-col cols="3"><div>Date:</div></v-col>
                                    <v-col cols="3"><div>{{ order.event.eventDate }}</div></v-col>
                                    <v-col cols="3"><div>Occasion:</div></v-col>
                                    <v-col cols="3"><div>{{ order.event.occasion }}</div></v-col>
                                    <v-col cols="3"><div>Role:</div></v-col>
                                    <v-col cols="3"><div>{{ order.eventRole }}</div></v-col>
                                    <v-col cols="3"><div>Type:</div></v-col>
                                    <v-col cols="3"><div>{{ order.event.eventType }}</div></v-col>
                                    <v-col cols="3"><div>Location:</div></v-col>
                                    <v-col cols="3"><div>{{ order.event.location }}</div></v-col>
                                </v-row>

                                <v-divider inset class="mr-5 my-5 mb-3"></v-divider>

                                <div class="display-1 font-weight-light grey--text text--darken-4">Rent Details</div>

                                <rent-details :use-date="order.useDate" :want-date="order.wantDate" :want-date-time="wantDateTime" 
                                    :return-date="order.returnDate"  :pickup-location="order.pickupLocationId" :event-role-id="order.eventRoleId" 
                                    :comments="order.comments" readonly ref="rentDetails"></rent-details>
                            </v-col>

                            <v-col cols="4">
                                <div class="display-1 font-weight-light grey--text text--darken-4">Rent Items</div>

                                <package-viewer :value="orderPackage" :store-id="order.storeId" :pickup-location="order.pickupLocation" />
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-subheader>Payments</v-subheader>

                                <v-data-table :headers="paymentHeaders" :items="order.payments" :footer-props="paginationOptions"
                                    item-key="paymentId" :options="pagination">
                                    <template v-slot:item.paymentDate="{ item }">
                                        {{ formatDate( item.paymentDate ) }}
                                    </template>
                                    <template v-slot:item.paymentAmount="{ item }">
                                        {{ formatMoney( item.paymentAmount ) }}
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="3">
                <v-card flat class="history">
                    <v-card-title class="headline font-weight-light grey--text text--darken-4">History</v-card-title>
                    <v-card-text class="px-0">
                        <history obj-type="order" :id="orderId"  :showForm="false" ref="history" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { API } from '@/inc/api';
import CustomerInfo from '@/components/CustomerInfo.vue';
import RentDetails from '@/components/RentDetails.vue';
import PackageViewer from '@/components/PackageViewer.vue';
import History from '@/components/History.vue';
import moment from 'moment';
import { _st } from '@/softech';

export default {
    props: {
        orderId: { type: [String, Number], default: 0 }
    },
    data: () => ({
        order           : null,
        loading         : false,

        paymentHeaders  : [
            { text: 'Date', value: 'paymentDate', sortable: true, width: '15%' },
            { text: 'Method', sortable: true, value: 'paymentMethod', width: '10%' },
            { text: 'Confirmation', value: 'confirmationNumber', sortable: true, width: '10%' },
            { text: 'Amount', value: 'paymentAmount', align: 'right', sortable: true, width: '10%' },
        ],
        paginationOptions: { itemsPerPageOptions: [ 25, 50, -1 ] },
        pagination      : {
            sortBy: ['paymentDate']
        },
    }),
    computed: {
        orderPackage() {
            if( this.order == null )
                return {};

            let promoCodes = this.order.otherCharges.filter(c => c.typeId == '6');
            let charges = this.order.otherCharges.filter(c => c.typeId == '3');

            // fixing the other charges amount value to comply with the package viewer format
            promoCodes.forEach(p => p.amount = Math.abs( p.amount ));

            return {
                items                   : this.order.items,
                promoCodes              : promoCodes,
                charges                 : charges,
                subtotalOverwrite       : this.order.subtotalOverwrite,
                subtotalOverwriteReason : this.order.subtotalOverwriteReason
            };
        },
        wantDateTime() {
            if( this.order == null )
                return '';
            
            return this.order.wantDate.split(' ')[1];
        },
        balance() {
            return this.order.balance <= 0 || ( this.order.noChargeReason != null && this.order.noChargeReason.length > 0 ) ? 0 : this.order.balance;
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let api = new API();

            try {
                this.loading = true;
                let res = await api.get(`/admin/order/${this.orderId}`);
                this.loading = false;

                if( res.data.status === false ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                if( _st.isNUE( res.data.data.id ) ) {
                    this.$router.push('/404');
                    return;
                }

                this.order = res.data.data;
                this.order.customer.rentalMeasurements = this.order.measurements;

                this.$refs.history.getHistory();
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage(error, 'error');
            }
        },
        formatDate(d, f = 'YYYY-MM-DD @ hh:mm a') {
            return moment(d).format(f);
        }
    },
    components: {
        CustomerInfo, RentDetails, PackageViewer, History
    }
}
</script>

<style lang="scss" scoped>
.history.v-card {
  max-height: 100vh;
  overflow: auto;
}
</style>